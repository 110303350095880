<template>
  <div>
    <v-navigation-drawer v-model="drawer" app right temporary>
      <v-list nav dense>
        <v-list-group class="my-1">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Nouse</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item link class="ml-5">
            <v-list-item-content>
              <v-list-item-title class="subtitle-2">Offre</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link class="ml-5">
            <v-list-item-content>
              <v-list-item-title class="subtitle-2"
                >Avantages</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <v-list-item link class="ml-5">
            <v-list-item-content>
              <v-list-item-title class="subtitle-2">Valeurs</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link class="ml-5">
            <v-list-item-content>
              <v-list-item-title class="subtitle-2">Flotte</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link class="ml-5">
            <v-list-item-content>
              <v-list-item-title class="subtitle-2"
                >Chauffeurs</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <v-list-item link class="my-1">
          <v-list-item-content>
            <v-list-item-title class="subtitle-2"
              >Reservation</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
        <v-list-group class="my-1">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Services</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item link class="ml-5">
            <v-list-item-content>
              <v-list-item-title class="subtitle-2"
                >Types de prestation</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <v-list-item link class="ml-5">
            <v-list-item-content>
              <v-list-item-title class="subtitle-2"
                >Compte professionnel</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>
      <v-divider class="mt-5 mb-8"></v-divider>
      <div class="btn-wrapper px-3">
        <v-btn block color="primary">Se connecter</v-btn>
      </div>
    </v-navigation-drawer>

    <v-app-bar app>
      <v-container>
        <div
          v-if="$vuetify.breakpoint.smAndDown ? true : false"
          class="mobile-nav d-flex flex-row justify-space-between align-center"
        >
          <v-img
            src="~@/assets/symbol.svg"
            min-height="50"
            max-width="50"
            contain
          ></v-img>
          <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
        </div>
        <div class="main-nav d-flex flex-row justify-space-between align-center" v-else>
          <v-toolbar-title>
            <v-img
              src="~@/assets/symbol.svg"
              min-height="75"
              max-width="75"
              contain
            ></v-img>
          </v-toolbar-title>

          <v-spacer></v-spacer>

          <div class="links-wrapper">
            <v-menu transition="scroll-y-transition" open-on-hover offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="secondary"
                class="ma-2"
                v-bind="attrs"
                v-on="on"
                plain
                text
              >
                Nous <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list style="width: 200px">
              <v-list-item link v-for="item in menuList" :key="item.id">
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-btn plain text class="mr-3"> Réservation </v-btn>

          <v-btn plain text class="mr-3"> Services </v-btn>

          <v-btn color="primary" elevation="0" :to="'/login'">
            Se connecter1
          </v-btn>
          </div>
        </div>
      </v-container>
    </v-app-bar>

    <v-main>
      <v-sheet max-width="70vh">
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat
          necessitatibus fugiat quis, libero impedit soluta eveniet accusantium
          delectus odit explicabo officia vel neque blanditiis facere excepturi
          illum nemo cumque dolore.
        </p>
        <p>
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Pariatur
          placeat quo totam atque quae cupiditate? Aliquam, minima labore,
          facere architecto quibusdam sequi ducimus eos molestias, facilis
          impedit optio et vel!
        </p>
      </v-sheet>
    </v-main>
    <v-footer padless fixed class="white">
      <v-card class="flex footer-inner" flat tile>
        <v-container>
          <v-card-title
            class="d-flex flex-column flex-md-row justify-md-space-between"
          >
            <div class="contact d-flex flex-column align-start">
              <v-btn
                v-for="item in contactList"
                :key="item.title"
                text
                class="white--text"
              >
                <v-icon small class="mr-2">{{ item.icon }}</v-icon>
                {{ item.title }}
              </v-btn>
            </div>

            <div class="socials mt-10">
              <v-btn v-for="icon in icons" :key="icon" class="mx-4" dark icon>
                <v-icon size="24px">
                  {{ icon }}
                </v-icon>
              </v-btn>
            </div>
          </v-card-title>
          <v-divider dark></v-divider>
          <v-card-text class="py-2 white--text text-center">
            {{ new Date().getFullYear() }} - <strong>Woto</strong>
          </v-card-text>
        </v-container>
      </v-card>
    </v-footer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      somevar: false,
      drawer: false,
      icons: ["mdi-facebook", "mdi-twitter", "mdi-linkedin", "mdi-instagram"],
      menuList: [
        {
          id: 1,
          title: "Offre",
          link: "",
        },
        {
          id: 2,
          title: "Avantages",
          link: "",
        },
        {
          id: 3,
          title: "Valeurs",
          link: "",
        },
        {
          id: 4,
          title: "Flotte",
          link: "",
        },
        {
          id: 5,
          title: "Chauffeurs",
          link: "",
        },
      ],
      contactList: [
        {
          title: "contact@woto.sn",
          icon: "mdi-at",
          link: "",
        },
        {
          title: "770000000",
          icon: "mdi-card-account-phone",
          link: "",
        },
        {
          title: "Address to woto office",
          icon: "mdi-map-marker",
          link: "",
        },
      ],
    };
  },
};
</script>

<style>
.footer-inner {
  max-width: 1920px;
  height: 575px;
  margin: 0 auto;
  background-color: teal;
  color: white;
  padding: 0px 25px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;

  background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.5)),
    url("../assets/illustrations/grande-img-03.jpg");
  background-size: cover;
  background-position: center;
}
</style>
